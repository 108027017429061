import React, { useState } from "react";
import classnames from "classnames";
import { useStoreState } from "pullstate";
import { parseCookies } from "nookies";

import PauseIcon from "@/icons/rounded-pause.svg";
import PlayIcon from "@/icons/rounded-play.svg";
import CheckIcon from "@/icons/smallCheckmark.svg";
import {
  PlayerStore,
  psChangeTime,
  psPauseAudio,
  psPlaySource,
} from "@/store/PlayerStore";
import { getDescriptiveFormatDuration } from "@/helpers/formatDuration";
import { PlayerStatusTypes } from "@/helpers/pageHelpers/Common/interface";
import Button from "@/components/Button";
import BookmarkButton from "@/components/BookmarkButton";
import ProgressBar from "@/components/Players/ProgressBar";
import Spinner from "@/components/Spinner";
import { ContentSourceEnum } from "@/components/BookmarkButton/BookmarkButton.interface";
import MatureArticleModal from "@/components/Modals/MatureArticleModal";
import { ProgressBarTypes } from "@/components/Players/ProgressBar/ProgressBar.interface";

import { EpisodePlayerInterface } from "./EpisodePlayer.interface";
import styles from "./EpisodePlayer.module.scss";

export const EpisodePlayer: React.FC<EpisodePlayerInterface> = ({
  metaData,
  variant = "light",
  size,
  url,
  duration,
  longVersionDuration,
  className,
  matureContent,
  time,
  bookmarkButtonSize = "small",
}) => {
  const [isMatureModalOpen, setMatureModal] = useState<boolean>(false);
  const {
    audioCurrentTime: currentTime,
    currentSource,
    isLoading,
    status,
  } = useStoreState(PlayerStore, (store) => ({
    audioCurrentTime: store.audioCurrentTime,
    currentSource: store.currentSource,
    isLoading:
      store.playerStatus === PlayerStatusTypes.Loading &&
      store.currentSource === url,
    status: store.playerStatus,
  }));
  
  const isActivePlayer = currentSource === url;
  const isPlaying = isActivePlayer && status === PlayerStatusTypes.Playing;
  const cookies = parseCookies();
  const cookieExist = cookies?.ageConfirmation !== undefined;
  const defaultTime = time | 0;
  const longDuration = longVersionDuration ? longVersionDuration : duration;
  const isCompleted = longDuration - defaultTime <= 0;

  const play = () => {
    PlayerStore.update(psPlaySource(url, metaData, time));
    if (isCompleted) {
      PlayerStore.update(psChangeTime(0));
    }
  };

  const stop = () => {
    PlayerStore.update(psPauseAudio);
  };

  const handlePlayButton = () => {
    if (!cookieExist && matureContent) {
      setMatureModal(true);
      return;
    } else {
      play();
    }
  };

  const closeMatureModal = () => {
    setMatureModal(false);
    // PlayerStore.update(psPlaySource(url, metaData));
  };

  return (
    <>
      <div
        className={classnames(
          styles.player,
          styles[variant],
          styles[size],
          className
        )}
      >
        <div className={styles.podcastPlayer}>
          <div className={styles.playerControls}>
            <ProgressBar
              isActivePlayer={isPlaying}
              duration={longDuration}
              currentTime={
                isPlaying || isActivePlayer ? currentTime : defaultTime
              }
              variant={ProgressBarTypes.RoundPrimary}
            />
            <div className={styles.playerInfo}>
              {isCompleted ? (
                <span className={styles.listened}>
                  Odsłuchano <CheckIcon className={styles.icon} />
                </span>
              ) : (
                <span className={styles.duration}>
                  {isPlaying || isActivePlayer
                    ? getDescriptiveFormatDuration(longDuration - currentTime)
                    : getDescriptiveFormatDuration(longDuration)}
                </span>
              )}
            </div>
          </div>
          <div className={styles.action}>
            <Button
              variant="playerPrimary"
              onClick={isPlaying ? stop : handlePlayButton}
              className={styles.playButton}
            >
              {isLoading ? (
                <Spinner />
              ) : isPlaying ? (
                <>
                  <PauseIcon />
                  Zatrzymaj
                </>
              ) : (
                <>
                  <PlayIcon />
                  Słuchaj
                </>
              )}
            </Button>

            <BookmarkButton
              variant="round"
              size={bookmarkButtonSize}
              slug={metaData.slug}
              source={ContentSourceEnum.Episodes}
              className={styles.bookmarkButton}
            />
          </div>

          <audio
            src={url}
            className={styles.audio}
            preload="none"
            suppressHydrationWarning
          >
            <source src={url} type="audio/mpeg" suppressHydrationWarning />
          </audio>
        </div>
      </div>

      {!cookieExist && matureContent && (
        <MatureArticleModal
          onClose={closeMatureModal}
          isOpen={isMatureModalOpen}
          callback={play}
        />
      )}
    </>
  );
};

export default EpisodePlayer;
