import { ClassName } from "@/helpers/pageHelpers/Common/interface";

export enum ProgressBarTypes {
  RoundPrimary = "roundPrimary",
}

export interface ProgressBarInterface {
  duration: number;
  currentTime: number;
  isDark?: boolean;
  isActivePlayer?: boolean;
  variant?: ProgressBarTypes;
  className?: ClassName;
}
