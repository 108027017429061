import Link from "next/link";
import Image from "next/image";
import classnames from "classnames";
import { parseCookies } from "nookies";
import { useStoreState } from "pullstate";

import Typography from "shared/components/Typography";
import { applicationRoutes } from "shared/helpers/applicationRouting";
import { PodcastTileInterface } from "shared/helpers/pageHelpers/Podcast/interface";
import EpisodePlayer from "shared/components/Players/EpisodePlayer";
import { getImgixURL } from "@/helpers/getImgixURL";
import { formatDate } from "@/helpers/formatDate";
import ContextualMenu from "@/components/ContextualMenu";
import { ContentSourceEnum } from "@/components/BookmarkButton/BookmarkButton.interface";
import { ContextualMenuEnum } from "@/components/ContextualMenu/ContextualMenu.interface";
import { PlayerStore } from "@/store/PlayerStore";

import styles from "./PodcastTile.module.scss";

export const PodcastTile: React.FC<PodcastTileInterface> = ({
  id,
  slug,
  title,
  publishedAt,
  podcast,
  bookmarked,
  media: { duration, longVersionDuration, subtitle, url, slug: mediaSlug },
  size,
  layout = "full",
  isDark,
  className,
  images,
  hosts,
  tags,
  index,
  time,
  sneakPeak,
  metaDescription,
  contextualMenuVariant,
  placement,
}) => {
  const { listenedEpisodes } = useStoreState(PlayerStore, (store) => store);
  const fullTitle = `${podcast.title} - ${subtitle}`;
  const podcastSlug = podcast.slug;
  const artwork = images.feed.lgx2;
  const mediaMetaData = {
    title: title,
    artwork: artwork,
    subTitle: podcast.title,
    slug: mediaSlug,
    podcast: { slug: podcast.slug },
    tags: !!tags?.length && tags.map(({ title }) => title),
    authors: !!hosts?.length && hosts.map(({ name }) => name),
    publishedAt,
    placement,
  };

  const cookies = parseCookies();
  const apiToken = cookies?.token;

  const audioURL = apiToken ? `${url}?api_token=${apiToken}` : url;

  const image = size === "medium" ? images.recommended : images.feed;

  const img = getImgixURL(image.lgx2);

  const episodeTime = listenedEpisodes?.[slug]?.status || time;

  return (
    <div
      className={classnames(
        styles.podcastTile,
        layout && styles[size],
        styles[size],
        className
      )}
      key={id}
    >
      <Link
        href={`${applicationRoutes.PODCAST_EPISODE}[slug]`}
        as={applicationRoutes.PODCAST_EPISODE + slug}
      >
        <a className={styles.image}>
          <Image
            layout="fill"
            objectFit="cover"
            objectPosition="center center"
            src={img}
            placeholder="blur"
            blurDataURL={`https://newonce.imgix.net/${img}?fm=blurhash`}
            alt={fullTitle}
            priority={index < 2}
            className={styles.img}
          />
        </a>
      </Link>
      <div className={styles.meta}>
        <div className={styles.action}>
          <Link
            href={`${applicationRoutes.PODCAST}[slug]`}
            as={applicationRoutes.PODCAST + podcastSlug}
          >
            <a className={styles.podcast}>
              <Typography component="span" variant="small">
                {podcast.title}
              </Typography>
            </a>
          </Link>
          <ContextualMenu
            slug={slug}
            podcast={{ slug: podcastSlug }}
            source={ContentSourceEnum.Episodes}
            variant={contextualMenuVariant || ContextualMenuEnum.Feed}
            duration={longVersionDuration}
            episodeTime={episodeTime}
            title={podcast.title}
            subtitle={subtitle}
            artwork={artwork}
          />
        </div>

        <Link
          href={`${applicationRoutes.PODCAST_EPISODE}[slug]`}
          as={applicationRoutes.PODCAST_EPISODE + slug}
        >
          <a className={styles.episodeLink}>
            <Typography component="h3" variant="h5" className={styles.title}>
              {subtitle}
            </Typography>
          </a>
        </Link>
        <time className={styles.uploadTime} dateTime={publishedAt}>
          {formatDate(publishedAt)}
        </time>
      </div>
      {size !== "small" && (
        <Link
          href={`${applicationRoutes.PODCAST_EPISODE}[slug]`}
          as={applicationRoutes.PODCAST_EPISODE + slug}
        >
          <a className={styles.episodeLink}>
            <Typography
              variant="small"
              component="p"
              className={styles.description}
            >
              {sneakPeak || metaDescription}
            </Typography>
          </a>
        </Link>
      )}
      <EpisodePlayer
        isDark={isDark}
        url={audioURL}
        duration={duration}
        longVersionDuration={longVersionDuration}
        publishedAt={publishedAt}
        bookmarked={bookmarked}
        metaData={mediaMetaData}
        size={size}
        matureContent={podcast.matureContent}
        time={episodeTime}
      />
    </div>
  );
};

export default PodcastTile;
