import React from "react";
import Skeleton from "react-loading-skeleton";

export const SkeletonLoader: React.FC<{
  count?: number;
  height?: number;
  width?: number;
}> = ({ count = 1, height = 268, width }) => (
  <Skeleton
    style={{ marginBottom: "24px" }}
    height={height}
    count={count}
    width={width}
  />
);

export default SkeletonLoader;
